/*
//BoxShadows below are from least intense, to most instense - just examples of good ones to use
//very light color, all the way around
boxShadow:'0 1px 4px rgba(0,0,0,.16)',
//very thin, tight spread, slightly darker than above
// boxShadow:'0 2px 2px 0 rgba(0,0,0,0.14),0 3px 1px -2px rgba(0,0,0,0.12),0 1px 5px 0 rgba(0,0,0,0.2)',
//slight more spread
boxShadow:'0 3px 4px 0 rgba(0,0,0,0.24)',      
//slightly thicker
//boxShadow:'0 4px 5px 0 rgba(0,0,0,0.14),0 1px 10px 0 rgba(0,0,0,0.12),0 2px 4px 0 rgba(0,0,0,0.20)',     
//slightly more spread than above
//boxShadow:'0 6px 10px 0 rgba(0,0,0,0.14),0 1px 18px 0 rgba(0,0,0,0.12),0 3px 5px -1px rgba(0,0,0,0.2)',
//wide spread
//boxShadow:'0 8px 17px rgba(0,0,0,0.2)',   
//wider spread below
//boxShadow:'0 12px 15px 0 rgba(0,0,0,0.24)',  
*/

//BoxShadows below are from least intense, to most instense
export const boxShadows = [
    {  boxShadow:'0 3px 4px 0 rgba(0,0,0,0.24)', }, //used on 'Step Components'
]
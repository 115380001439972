import React from "react"
import MyLayout from "../components/layout"
import SEO from "../components/seo"
import LandingPage, { getScrollToLinks } from "../components/landingPage"

const IndexPage = () => (
  <MyLayout 
    headerScrollToLinks={getScrollToLinks()}
    showChat={true}
    >
    <SEO title="Home" />

    <LandingPage />
  </MyLayout>
)

export default IndexPage

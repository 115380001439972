import React from "react"
import { Col, Row } from "antd"

import "./landingPage.css"

import { fontStyle, subheaderFontSize, lighterTeal } from "./statics"

//This could be cleaned up a little..
const MainTitle = () => {
  return (
    <>
      <Col>
      <Row
      align={"middle"}
      >
      <p style={{ ...fontStyle, ...{ 
        //lineHeight: 1, 
        fontSize: subheaderFontSize + 4,
        marginBottom: 20,  } }}>
            The Intelligent App Made For Meal Time
          </p>
        </Row>

       
        <Row>
         <p style={{ 
             ...fontStyle, 
             ...{ 
              //lineHeight: 1, 
              marginBottom: 20, 
              fontSize: subheaderFontSize - 10, 
             }
            }}>
            Time is better spent eating together, not on meal planning and grocery shopping
          </p>
        </Row>
        

        
       </Col>
    </>
  )
}
export default MainTitle

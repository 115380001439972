import React from "react";
import PropTypes from "prop-types";
import { Col, Row, Icon, Carousel } from "antd";
import SearchRecipeScreenshotImage from "./iphoneSearchRecipes";
import GListImage from "./iphoneGlist";
import ShopScreenImage from "./iphoneShop";
import FAQ from "./faq";
import MainTitle from "./mainTitle";
import DownloadSection from "./downloadSection";
import "./landingPage.css";
import { fontStyle, subheaderFontSize, sectionPadding, lighterTeal, darkerTeal } from "./statics";
import LandingSteps from "./landingSteps";

const ARTICLES = "Articles";
let links = [{ linkText: ARTICLES, linkPath: "/blogs" }];

//drives the 'scroll to' behavior on the header
const HOW_TO = "How To";
const DOWNLOAD = "Download";
const FAQ_REF = "FAQ";
let scrollToSections = [DOWNLOAD, HOW_TO, FAQ_REF];
var refs = {};
//refs obj contains props, that are the 'string' that matches the reference for easier assigning refs to components
//ie: ref = ref[HOW_TO]
scrollToSections.forEach(element => (refs[element] = React.createRef()));
//need a method that returns linkText + refs for this page
export const getScrollToLinks = () => {
  let scrollToLinks = scrollToSections.map(element => {
    return { linkText: element, ref: refs[element] };
  });

  let arrObjs = scrollToLinks.concat(links); //combine
  //console.log('getScrollToLinks arrObjs: ', JSON.stringify(arrObjs));
  return arrObjs;
};

const carouselComponent = (
  { iconType, text, imgComponent } = {
    iconType: "",
    text: "",
    imgComponent: null,
  }
) => {
  return (
    <div>
      <Icon type={iconType} style={{ color: darkerTeal, fontSize: 30 }} />

      <Row
        type="flex" //important or justify does not work
        justify="center"
        style={{
          flex: 1,
          paddingBottom: 5,
          paddingTop: 5,
          //paddingLeft: 20, paddingRight: 20,
        }}
      >
        <Col
          span={18} //need to set to 21..
          style={
            {
              //backgroundColor:'green'
            }
          }
        >
          <p
            style={{
              ...fontStyle,
              ...{
                textAlign: "center",
                marginBottom: 5,
                fontSize: subheaderFontSize - 6 + "px",
              },
            }}
          >
            {text}
          </p>
        </Col>
      </Row>
      {imgComponent}
    </div>
  );
};

const LandingPage = ({ children }) => {
  return (
    <>
      <Row
        style={{
          paddingTop: sectionPadding / 2,
          paddingBottom: sectionPadding,
          paddingLeft: "18%", //padding tends to matter on very small view
          paddingRight: "18%",
          boxShadow: "0px 13px 15px -20px rgba(0,0,0,0.75)",
          background: "linear-gradient(rgba(255,0,0,0) 0%, #f0faf6 7%, #c3d9d5 100%)",
          //backgroundColor:'red',
        }}
      >
        <MainTitle />
        <Row>
          <Col
            span={24}
            style={
              {
                //backgroundColor:'blue'
              }
            }
            align={"center"}
          >
            <div
              style={{
                //directly impacts image size
                //maxWidth + <image_x>.js props seem to prevent auto-sizing (responive) images, ok for now.
                maxWidth: "35vh",
                //backgroundColor:'green'
              }}
            >
              <Carousel
                autoplay
                effect="scrollx" //or 'fade'
                dots={true}
                speed={1700} //transition speed from image 1 to 2
                autoplaySpeed={4000} //time to stay still on image, 4 seconds
                style={{ paddingBottom: 35, paddingTop: 20 }}
                //swipeToSlide={true} //enable swipe on phone? 'swipe' is default to true so may no need this
              >
                {/*Nested Divs appear 1 at a time in carousel, even though below they appear nested as rows*/}

                {carouselComponent({
                  iconType: "book",
                  text: "Select Weekly Recipes",
                  imgComponent: <SearchRecipeScreenshotImage />,
                })}

                {carouselComponent({
                  iconType: "check-circle",
                  text: "Smart Grocery List",
                  imgComponent: <GListImage />,
                })}

                {carouselComponent({
                  iconType: "shopping",
                  text: "Shop in app",
                  imgComponent: <ShopScreenImage />,
                })}
              </Carousel>

              {/*Begin download now shortcut
<div style={{
  height:100,
  width: 200, 
  backgroundColor:'green',
  borderRadius:'15px',
}}>
asdf
{/*Teal Button, White Text, Jumps to bottom of /}
</div>
*/}
            </div>
          </Col>
        </Row>{" "}
        {/*End Carousel Row*/}
      </Row>{" "}
      {/*End First Large Component Row*/}
      {/*<hr style={{ backgroundColor: "lightgray" }}></hr> Divider*/}
      {/*How to go from recipe to table*/}
      <Row
        style={{
          paddingTop: sectionPadding,
          //backgroundColor: "white",
        }}
      >
        <Col
          style={{
            width: "50%",
            margin: "0 auto", //center content horizontally
            align: "center",
            //backgroundColor:'blue',
          }}
        >
          <div ref={refs[HOW_TO]}>
            <p
              //in ReactNative you can pass array of style {[A, {b:b_sub}]}
              //in React Web you must pass 1 single style object {{...A, ...B}}
              style={{
                ...fontStyle,
                ...{
                  fontSize: subheaderFontSize + "px",
                  color: "#646464",
                  fontWeight: "bold",
                },
              }}
            >
              How to go from recipe to table
              <div style={{ alignItems: "center", paddingTop: 3 }}>
                <hr
                  style={{
                    height: 2,
                    width: 80,
                    align: "center",
                    backgroundColor: lighterTeal,
                  }}
                ></hr>{" "}
                {/*Divider*/}
              </div>
            </p>
          </div>
        </Col>
      </Row>
      <div style={{ paddingBottom: sectionPadding }}></div>
      {/*Vertical spacer*/}
      <LandingSteps />{" "}
      {/*equivalent to {LandingSteps()} , 3 large containers with pictures and text explaining app*/}
      {/*<hr style={{backgroundColor:'lightgray', margin:0,}}></hr> {/*Divider*/}
      {/*Begin Additional Features Section  
 
   <Row
style={{
   paddingTop: sectionPadding,
  //backgroundColor:'green'
  //paddingBottom: sectionPadding,
}}
>
<Col //span={24}
  style={{
  width: '50%',
  margin: '0 auto', //center content horizontally
  //backgroundColor:'blue',
   align:"center",
}}>
  <div>
  <p
    style={{...fontStyle, ...{ 
                         fontSize: subheaderFontSize - 4 + 'px', 
                         color: '#646464',
                        }
          }}
   >Additional Features
   <div style={{alignItems:'center', paddingTop: 3}}>
     <hr style={{
        height:2,
        width: 80,
        align:'center',
        backgroundColor: lighterTeal,   
    }}></hr>  /*Divider 
    </div>

</p>

   </div>



</Col>
</Row>

<Row
style={{
   //paddingTop: sectionPadding,
  //backgroundColor:'green'
  //paddingBottom: sectionPadding,
}}
>
<Col //span={24}
  style={{
 // width: '50%',
  //margin: '0 auto', //center content horizontally
  //backgroundColor:'blue',
   //align:"center",
}}>
  <p>hiya</p>
</Col>
</Row>
 End Additional Features Section
*/}
      {/*Begin Download App Section*/}
      <DownloadSection mRef={refs[DOWNLOAD]} />
      {/*End Download App Section*/}
      {/*<hr style={{backgroundColor:'lightgray'}}></hr> Divider*/}
      <Col
        style={{
          paddingTop: sectionPadding,
          paddingBottom: sectionPadding,
          marginLeft: "12%",
          marginRight: "12%",
        }}
      >
        <p
          style={{
            ...fontStyle,
            ...{
              fontSize: subheaderFontSize - 4 + "px",
            },
            paddingBottom: 20,
          }}
          ref={refs[FAQ_REF]}
        >
          Frequently Asked Questions
        </p>

        <FAQ />
      </Col>
    </>
  );
};

LandingPage.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LandingPage;

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const ImageBanner = () => {
  //warning maxWidth: 1280 directly impacts image quality
  //1280 because pixlr create new says 1280 is max for web, not sure if general rule of thumb, 1280 is plenty?
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "girlcookingblur.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Img
      fluid={data.placeholderImage.childImageSharp.fluid}
      fadeIn={true}
      durationFadeIn={500}
      //paddingTop zooms in on pic a little
      style={{
        height: "100%", //seems to be an improvement on vertical alignment?
      }}
    />
  )
}

export default ImageBanner

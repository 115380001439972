/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { Collapse } from "antd"

import {
  fontStyle,
  subheaderFontSize,
  ANDROID_DOWNLOAD_LINK,
  iOS_DOWNLOAD_LINK,
} from "./statics"

const { Panel } = Collapse

const FAQ = () => {
  //extracting this out makes 'ANDROID_DOWNLOAD_LINK' undefined, so careful
  const costHeader = "What does it cost?"
  const costExplanation = `Fork More is free to try, for everyone.\n 
  If you hit the app limits of use, then you will see that you need a subscription.
  What you pay monthly (if you choose to subscribe) is $0.99 per month.\n
  Compared to some of our competitors who can charge more than 8$ a month.\n
  Users should also be aware: some competing apps may seem like a one time purchase, but users may be asked to re-buy a 'new app version' every year, and potentially at a different price point.
  
  Cancel a subscription anytime:
  iOS cancel subscription through iTunes.
  Android cancel subscription through Google Play.
  `

  const shareHeader = "Can I share my recipes and grocery list with my family?"
  const shareExplanation = `Fork More is intended to be shared with members of your household.
  To share your app data: Click on Menu (top left of screen) > Sync > Invite a user.
  When a user accepts an invite, all users will see updates across many features of the app including: Recipes, Grocery List, and Favorite Grocery List Items.
  Download on Android Link: ${ANDROID_DOWNLOAD_LINK}
  Download on iOS Link: ${iOS_DOWNLOAD_LINK}
  `

  const mainFeatsHeader = "Why would I want to use this?"
  /*
  Recipes:
     Browse online for a recipe you want and save it in-app. We do our best to figure out how the data maps to each piece of a recipe.
     Create your own recipe: Set the Image, Category (Entree, Side, etc.), Servings, Prep/Cook Time, Description, Directions and Ingredients yourself.
     Take a picture of a recipe from a book: Image quality, and text alignment of the data can cause negative results with this feature.
  Add Recipes to Grocery List:
     We believe the fastest way to complete a trip to the grocery store, is to know what meals you're going to want to make that week.
     Add a recipe from the 'Recipe Library' screen, Click 'View Selected Recipes', Modify Servings then click 'Add Ingredients to List'.
     The grocery list will automatically try to identify ingredients, group similar ingredients together and then place each item into a store category.
     Generally, the grocery list will try to convert generic measurements to ounces because in the United States most grocery items are described in ounces.
     If you need to create your own ingredient or brand-name item that you buy frequently, just add a favorite item and set the category yourself.
     You can add a favorite by going ot the 'Grocery List' screen and clicking on the 'Star' icon at the top right.
     */
  const mainFeatsExplanation = `Save Recipes from the internet, a picture, or you can manually enter the data yourself.
            \nWe convert recipes to shopping list items for you. Different ingredients will be combined together for you, categorized and converted to ounces when possible.
            \nApp data can be shared between users, and we integrate with Grocery Stores so you can order your grocery list directly in the app.
            \nWe hope Fork More can save you time on your weekly grocery shopping, and help keep your recipes organized!
     `

  const faqArr = [
    [mainFeatsHeader, mainFeatsExplanation],
    [shareHeader, shareExplanation],
    [costHeader, costExplanation],
  ]

  return (
    <Collapse accordion>
      {faqArr.map((element, index) => {
        return (
          <Panel
            header={element[0]}
            key={index}
            style={{
              ...fontStyle,
              ...{
                fontSize: subheaderFontSize - 6 + "px",
                backgroundColor: "white",
                textAlign: "left",
                //color: '#646464',
                paddingTop: 4,
                paddingBottom: 4,
              },
            }}
          >
            {element[1].split("\n").map((paragraph, index) => {
              return (
                <p
                  align="left"
                  key={index}
                  style={{
                    ...fontStyle,
                    ...{
                      fontSize: subheaderFontSize - 7 + "px",
                      lineHeight: 1.5,
                      textAlign: "left",
                    },
                  }}
                >
                  {paragraph}
                </p>
              )
            })}
          </Panel>
        )
      })}

      {/*Example
    <Panel header="This is panel header 3" key="3">
      <p>{text}</p>
    </Panel>*/}
    </Collapse>
  )
}

export default FAQ

import React from "react"
import {
  fontStyle,
  subheaderFontSize,
  sectionPadding,
  subtleBackgroundColor,
} from "./statics"
import { Col, Row } from "antd"
import { boxShadows } from "../styles/reactStyles"
import {
  RecipeStepsObj,
  ShoppingStepsObj,
  CookStepsObj,
} from "./landingStepsData"

const smallerWidthDivider = () => (
  <hr
    style={{
      marginTop: 20,
      backgroundColor: "lightgray",
      marginLeft: "15vw",
      marginRight: "15vw",
      height:1, //without may not display (view width depending/random)
    }}
  ></hr>
)

const ScreenshotComponent = ({
  imgComponentFunc = null,
  dbug = false,
  dbugColor = "blue",
} = {}) => {
  let mBackgroundColor = dbug ? dbugColor : null

  return (
    <Col
      span={15}
      style={{
        backgroundColor: mBackgroundColor,
        paddingBottom: "4%",
        paddingTop: "4%",
      }}
    >
      <div style={{ width: undefined, maxHeight: 400 }}>
        {imgComponentFunc && imgComponentFunc()}
      </div>
    </Col>
  )
}

const ExtraInfoComponent = ({
  imgComponentFunc = null,
  text = null,
  paddingTop = true,
  dbug = false,
  dbugColor = "green",
} = {}) => {
  let mBackgroundColor = dbug ? dbugColor : null

  return (
    <Col
      xs={10}
      sm={9}
      md={9}
      lg={9}
      xl={9} //collapse underneat if not enough width
      style={{
        //paddingTop: paddingTop ? '5%' : 0, //a guess to vertically align contents since others did not work
        backgroundColor: mBackgroundColor,
        //backgroundColor:'pink',
      }}
    >
      {/*div needed to vertically align the <p> tag I guess, not sure why*/}
      <div
        style={{
          height: "100%",
          width: "100%",
          justifyContent: "center", //vertical align
          //alignItems:'center',
          //backgroundColor:'yellow',
        }}
      >
        {text && (
          <p
            //align='center' //vertical align text -probably not needed
            style={{
              ...fontStyle,
              ...{
                whiteSpace: "pre-line", //respect nested '\n' as new line characters
                //display:'inline-block', //probably not needed
                fontSize: subheaderFontSize - 12 + "px",
                //wordWrap:'break-word',
                //marginBottom:12,
                //marginLeft: 15, marginRight: 15,
                //backgroundColor:'pink',
              },
            }}
          >
            {text}
          </p>
        )}

        {imgComponentFunc && (
          <div
            style={{
              marginTop: 5,
              maxHeight: 200,
              //backgroundColor:'green',
            }}
          >
            {imgComponentFunc()}
          </div>
        )}
      </div>
    </Col>
  )
}

//only used in advancedStepComponent
const stepStyle = {
  ...fontStyle,
  ...{
    //color:'black',
    fontSize: subheaderFontSize - 4 + "px",
    marginBottom: 5,
    marginLeft: 15,
    marginRight: 15,
  },
}

const advancedStepComponent = ({
  headerText = null,
  mainImageFunc = null, //not '()=>{}' because ExtraInfoComponent will attempt div render
  extraInfoText = null,
  mainInfoFirst = true, //if false then main image aligns right column
  extraInfoImageFunc = null,
} = {}) => {
  return (
    <div>
      {smallerWidthDivider()}
      <div style={{ marginBottom: sectionPadding / 2 }}></div>{" "}
      {/*Vertical Spacer between next steps*/}
      <p align={"center"} style={stepStyle}>
        {headerText}
      </p>
      <Row
        type={"flex"}
        justify={"start"}
        align={"center"}
        style={{
          //backgroundColor:'red',
          paddingLeft: "5%",
          paddingRight: "5%", //on very wide views, bring the content together
        }}
      >
        {/*Whichever comes 2nd, will get wrapped underneath, if not enough width*/}
        {!mainInfoFirst &&
          ExtraInfoComponent({
            text: extraInfoText,
            imgComponentFunc: extraInfoImageFunc,
          })}

        {ScreenshotComponent({
          imgComponentFunc: mainImageFunc,
        })}

        {/*Begin extra info on Right Column, will go underneath if not wide enough*/}

        {mainInfoFirst &&
          ExtraInfoComponent({
            text: extraInfoText,
            imgComponentFunc: extraInfoImageFunc,
          })}
      </Row>
    </div>
  )
}

const StepContainer = ({ headerText = null, steps = [] } = {}) => {
  return (
    <Row
      style={{
        paddingBottom: sectionPadding,
        //backgroundColor:'green',
      }}
    >
      <Col
        //span={24}
        style={{
          width: "85%",
          maxWidth: 800,
          margin: "0 auto", //center content horizontally
          //backgroundColor:'blue',
        }}
      >
        {/*Begin Container Component with BoxShadow and Rounded Lower Corners*/}
        <div
          style={{
            backgroundColor: "white", //change back to white
            borderBottomLeftRadius: 20,
            borderBottomRightRadius: 20,
            ...boxShadows[0], //combine styles in react web
          }}
        >
          <Row
            justify={"center"}
            align={"center"}
            type={"flex"}
            style={{
              marginTop: 20,
              paddingBottom: 20,
            }}
          >
            <Col
              span={24}
              align={"center"}
              style={
                {
                  //backgroundColor:'green'
                }
              }
            >
              <p
                style={{
                  ...fontStyle,
                  ...{
                    color: "black",
                    marginBottom: 5,
                    display: "inline-block",
                  },
                }}
              >
                {headerText}
              </p>
            </Col>
          </Row>

          {steps.map(element => {
            return advancedStepComponent(element)
          })}
        </div>
      </Col>
    </Row>
  )
}

const LandingSteps = () => {
  return (
    <>
      {StepContainer(RecipeStepsObj)}
      {StepContainer(ShoppingStepsObj)}
      {StepContainer(CookStepsObj)}
    </>
  )
}
export default LandingSteps

import React from "react"
import GListImage from "./iphoneGlist"
import ImportWebScreenShot from "./iphoneimportweb"
import EditScreenShot from "./iphoneeditscreenshot"
import SearchRecipeScreenshotImage from "./iphoneSearchRecipes"
import ShopScreenImage from "./iphoneShop"
import ViewRecipeScreenshot from "./iphoneviewrecipe"

const SELECT_RECIPES_MAIN_HEADER_TXT = "Select This Week's Recipes"
const SAVE_WEBSITE_HEADER_TXT = "Easily Save Recipes From Websites."
const SAVE_WEBSITE_EXTRA_TXT =
  "Save from all of your favorite recipe websites and blogs."
const CREATE_RECIPE_HEADER_TXT = "Or Create Your Own Recipes."
const CREATE_RECIPE_EXTRA_TXT =
  "Enter Multiple Ingredients or Instructions, Copy/Paste Text, or Use An Image Of A Recipe."
const ADD_RECIPES_HEADER_TXT = "Add Recipes To Your Grocery List."
const ADD_RECIPES_EXTRA_TXT =
  "Select Recipes that take less time to make week nights easier.\n\n\
Easily change servings for exact number of guests, or scale up to make leftovers."

export let RecipeStepsObj = {
  headerText: SELECT_RECIPES_MAIN_HEADER_TXT,
  steps: [
    {
      /*Save Recipes From Websites*/
      /*Maybe if we have features, or extended info 'new websites come out every day, those may work too*/
      headerText: SAVE_WEBSITE_HEADER_TXT,
      mainImageFunc: () => {
        return <ImportWebScreenShot />
      },
      extraInfoText: SAVE_WEBSITE_EXTRA_TXT,
      //todo replace with image of some logos, food network, etc.. - need to ask permission for copyright before using logos
      //extraInfoImageFunc: ()=> { return <GListImage/> },
    },
    {
      /*Create Your Own Recipes*/
      mainInfoFirst: false, //Large Screenshot Image Comes After Extra Info Column
      headerText: CREATE_RECIPE_HEADER_TXT,
      mainImageFunc: () => {
        return <EditScreenShot />
      } /*TODO replace with Modify Recipe Screen*/,
      extraInfoText: CREATE_RECIPE_EXTRA_TXT,
      //intentionally did not want an image in the extra info column
      //extraInfoImageFunc: ()=> { return <GListImage/> },
    },
    {
      /*Add Recipes To Grocery List*/
      headerText: ADD_RECIPES_HEADER_TXT,
      mainImageFunc: () => {
        return <SearchRecipeScreenshotImage />
      } /*View Recipes 3/6 selected, could have added Selected Recipes Screen*/,
      extraInfoText: ADD_RECIPES_EXTRA_TXT,
      //intentionally did not want an image in the extra info column
      //extraInfoImageFunc: ()=> { return <GListImage/> },
    },
  ],
}
/*End Select Recipe Steps*/
/*Begin In App Shopping Steps*/
const SHOPPING_MAIN_HEADER_TXT = "In-App Shopping"
const LIST_HEADER_TXT = "A Smart Grocery List"
const LIST_EXTRA_TXT = "Grocery List Is Shared, and Backed Up In The Cloud." //use list in-store?
const RETAIL_API_HEADER_TXT = "Order For Pick-Up Or Delivery"
const RETAIL_API_EXTRA_TXT =
  "Your Favorite Retailers, No Hidden-Fees or Item Mark-Ups."
export let ShoppingStepsObj = {
  headerText: SHOPPING_MAIN_HEADER_TXT,
  steps: [
    {
      headerText: LIST_HEADER_TXT,
      mainImageFunc: () => {
        return <GListImage />
      }, //big image: Grocery List, text: 'A smart grocery list shared between users.'
      extraInfoText: LIST_EXTRA_TXT,
      //extraInfoImageFunc: ()=> { return <GListImage/> }, //minor image: people in bubbles or something to show 'syncing'
    },

    //JUST a minor image + text?
    //A picture of a fruit/vegetable would really break up the screenshots here
    //6 cloves garlic , like image?, text: 'We understand ingredients' - show graphic of breakdown of text parts

    {
      mainInfoFirst: false,
      headerText: RETAIL_API_HEADER_TXT,
      mainImageFunc: () => {
        return <ShopScreenImage />
      }, //Big image screenshot in-app scrolling through retailer,
      extraInfoText: RETAIL_API_EXTRA_TXT,
      //intentionally no extraInfoImage
    },
  ],
}
/*End In App Shopping Steps*/
/*Begin Cook Steps*/
/*
Other potential text?:
Select Recipes that take less time to make week nights easier.
Easily change servings for exact number of guests, or scale up to make leftovers.
*/
const COOK_MAIN_HEADER_TXT = "Enjoy Making Recipes"
const COOK_HEADER_TEXT = "No Ads, No Distractions"
const COOK_EXTRA_TEXT =
  "Screen stays on while you cook, so you don't miss a step."
export let CookStepsObj = {
  //big image: View Recipe, text: Cook With A Clean UI, Without Distractions
  //minor text: Stays on while you cook, so you don't miss a step.
  headerText: COOK_MAIN_HEADER_TXT,
  steps: [
    {
      headerText: COOK_HEADER_TEXT,
      mainImageFunc: () => {
        return <ViewRecipeScreenshot />
      }, //todo: View Recipe Image
      extraInfoText: COOK_EXTRA_TEXT,
    },
  ],
}
/*End Cook Steps*/

import React from "react";
import { Col, Row, message } from "antd";
import ImageBanner from "./imageBanner";
import AppIcon from "./appIcon";
import { fontStyle, subheaderFontSize, ANDROID_DOWNLOAD_LINK, iOS_DOWNLOAD_LINK } from "./statics";

const getLineBreak = (isClipboard = false) => (isClipboard ? "\n" : "%0D%0A");

//if copying link to clipboard just use: \n
//if copying link to open in email app use encoded lineBreak
//Change would need to confirm: Click on CopyToClipboard > Paste in Notebad, links still work + Email Links button works with newline

const dloadPrefixAndroid = `Download on Android:`;
const dloadPrefixIOS = `Download on iOS:`;

const androidDloadStr = `${dloadPrefixAndroid} ${ANDROID_DOWNLOAD_LINK}`;
const iosDloadStr = `${dloadPrefixIOS} ${iOS_DOWNLOAD_LINK}`;

const getLinksText = ({ isStandardLineBreak }) =>
  `${androidDloadStr}${getLineBreak(isStandardLineBreak)}${iosDloadStr}`;

const mailToVar = `mailto:?subject=Check out Fork More!&body=${getLinksText({
  isStandardLineBreak: false,
})}`;

const containerHeight = 900;

const DownloadSection = ({ mRef }) => {
  return (
    <>
      <Row
        style={
          {
            //paddingTop:200,
            //backgroundColor:'orange',
          }
        }
      >
        <Col
          //span={24}
          style={{
            //width: '100%',
            //margin: '0 auto', //center content horizontally
            height: containerHeight,
            //backgroundColor:'blue',
          }}
        >
          {/*Person Cooking Image*/}
          <Row>
            <Col
              span={24}
              style={{
                //backgroundColor:'green',
                height: containerHeight,
                maxWidth: "100vw",
              }}
            >
              <ImageBanner />
            </Col>
          </Row>

          {/*White over top of image*/}
          <div
            style={{
              backgroundColor: "white",
              position: "absolute",
              width: "100%",
              height: "100%",
              opacity: "70%",
            }}
          />

          {/* example of centered text over absolute positioned 'div'
<div class="centered"
style={{
position: 'absolute',
top: '50%',
left: '50%',
transform: 'translate(-50%, -50%)',
}}>Centered</div>*/}

          <div
            style={{
              position: "absolute",
              justifyContent: "space-around",
              alignSelf: "center", //horizontal alignment of this container
              alignItems: "center", //horizontal alignment of child components
              height: "100%", //div height shouldn't be larger than parent
              paddingTop: 10,
              paddingBottom: 10,
              //paddingBottom:'15%',
              //marginLeft: '18%',  //oddly removing, centers content
              //overflowY: 'auto', //makes scrollable (bad on mobile?)
              //backgroundColor:'pink',
            }}
            ref={mRef} //to jump to this component from the shortcut link at top of page
          >
            <Row style={{ flex: 1, alignItems: "center" }}>
              <p
                style={{
                  ...fontStyle,
                  ...{
                    fontSize: subheaderFontSize - 4,
                    //color: '#646464', //medium gray
                    //paddingBottom:'5vw',
                    marginBottom: 30,
                    margin: 0,
                  },
                }}
              >
                Download The App
                <br />
                <p style={{ ...fontStyle, fontSize: subheaderFontSize - 10 }}>
                  No commitment,{" "}
                  <p
                    style={{
                      ...fontStyle,
                      fontSize: subheaderFontSize - 10,
                      textDecoration: "underline",
                      display: "inline",
                    }}
                  >
                    Free
                  </p>{" "}
                  to try.
                </p>
              </p>
            </Row>

            <Row
              style={{
                //backgroundColor:'pink',
                flex: 2,
                alignItems: "center",
              }}
            >
              <Col
                style={
                  {
                    // backgroundColor:'orange',
                  }
                }
              >
                <Row
                  style={{
                    flex: 1,
                    //backgroundColor:'green',
                    justifyContent: "center",
                    paddingBottom: 20,
                  }}
                >
                  <div
                    style={{
                      maxHeight: "24vh",
                      maxWidth: "24vh",
                      //backgroundColor:'green',
                    }}
                  >
                    <AppIcon />
                  </div>
                </Row>

                <Row
                  style={{
                    // backgroundColor:'blue',
                    justifyContent: "flex-end",
                    alignContent: "flex-end",
                    flex: 1,
                  }}
                >
                  {/*Begin Google Play Button*/}
                  <a
                    style={
                      {
                        //width: '50px',
                        //marginBottom:'0',
                        //marginTop:'5vh',
                      }
                    }
                    href={ANDROID_DOWNLOAD_LINK}
                  >
                    <img
                      style={{
                        //limit the size of the very large google badge image
                        //maxWidth:'180px',
                        height: "10vh",
                        width: "24vh",
                        margin: 0,
                      }}
                      alt="Get it on Google Play"
                      src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                    />
                  </a>
                </Row>
                {/*End Google Play Button*/}

                {/*Begin iOS Button*/}
                <Row
                  style={{
                    //backgroundColor:'blue',
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <a href={iOS_DOWNLOAD_LINK}>
                    <img
                      style={{
                        width: "21vh",
                        height: "10vh",
                        marginBottom: 0,
                      }}
                      alt="Get it on iOS"
                      src="https://linkmaker.itunes.apple.com/en-us/badge-lrg.svg?releaseDate=2009-10-15&kind=iossoftware&bubble=ios_apps"
                    />
                  </a>

                  {/*End iOS Button */}
                </Row>
              </Col>
            </Row>

            <Row style={{ flex: 2, justifyContent: "center", alignItems: "center" }}>
              {/*Begin send links buttons*/}
              <div
                style={{
                  margin: 0,
                  padding: 0,
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "20%",
                  marginRight: "20%",
                  //backgroundColor:'green'
                }}
              >
                <p
                  align="center"
                  style={{
                    ...fontStyle,
                    fontSize: subheaderFontSize - 12,
                    //color: '#646464', //medium gray
                    margin: 0,
                    fontWeight: "bold",
                  }}
                >
                  Try it later:
                  <br />
                  <p
                    style={{
                      ...fontStyle,
                      fontSize: subheaderFontSize - 12,
                      margin: 0,
                      fontWeight: "normal",
                    }}
                  >
                    Send download links to yourself, friends or family.
                  </p>
                </p>

                {/*Begin copy link button */}
                <div style={{ marginTop: 20 }} />
                <button
                  style={{
                    ...fontStyle,
                    ...{
                      fontSize: subheaderFontSize - 12,
                      borderRadius: 12,
                      borderWidth: 0.5,
                      minWidth: 200, //keep in sync with button above
                      backgroundColor: "white",
                    },
                  }}
                  onClick={() => {
                    navigator.clipboard.writeText(getLinksText({ isStandardLineBreak: true }));
                    message.success("Copied to Clipboard");
                  }}
                >
                  Copy Links to Clipboard
                </button>
                {/*End copy link button */}

                <div style={{ marginTop: 20 }} />

                <form
                  method="post" //post is very important for making nested 'mailTo' work (do not use 'get')
                  //enctype="text/plain" //we are encoding, so do not uncomment
                  action={mailToVar}
                  style={{
                    marginBottom: 0,
                  }}
                >
                  {/*<input type="submit" value="Submit"/>would also, create a button that submits */}
                  <button
                    style={{
                      ...fontStyle,
                      ...{
                        fontSize: subheaderFontSize - 12,
                        margin: 0,
                        borderRadius: 12,
                        borderWidth: 0.5,
                        minWidth: 200, //keep in sync with button below
                        backgroundColor: "white",
                      },
                    }}
                    type="submit"
                  >
                    Email App Links
                  </button>
                </form>
              </div>
            </Row>
            {/*End send links buttons*/}

            {/*allow users to manually copy/paste the link themselves*/}
            <p
              align="center"
              style={{
                ...fontStyle,
                fontSize: subheaderFontSize - 17,
                //color: '#646464', //medium gray
                margin: 0,
              }}
            >
              {dloadPrefixAndroid}
              <br />
              {ANDROID_DOWNLOAD_LINK}
              <br />
              <br />
              {dloadPrefixIOS}
              <br />
              {iOS_DOWNLOAD_LINK}
              <br />
              <br />
            </p>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default DownloadSection;
